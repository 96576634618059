import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Card from '../components/card/card'
import Research from '../components/research/research'
import Seo from "../components/seo"

import { projects } from "../components/data/projects";
import { research } from "../components/data/research";

import { IndexPageIntroAnimation } from "../components/utils/page-animation"
import Intro3d from "../components/intro-3d-scene"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => {

  const [cameraAngle, setCameraAngle] = useState([0,0]);

  useEffect(() => {
    IndexPageIntroAnimation();
    const mainSection = document.querySelector('.main-section');
    const mainSectionHeight = mainSection.offsetHeight;
    const mainSectionWidth = mainSection.offsetWidth;
    mainSection.addEventListener('mousemove', (e)=>{
      setCameraAngle([-1 * e.clientY/mainSectionHeight, -1 * e.clientX/mainSectionWidth]);
    })
  },[]);

  return (
  <>
    <Seo title="Home" />
    <section className={`main-section fix-bottom`}>
      <div className={`three-d-back`}>
        <Intro3d cameraAngle={cameraAngle} />
      </div>
      <div className={`center-content full-height`}>
      <div className={`content`}>
        {/* <div className={`confetti-wrapper`}>
          <div className={`confetti`}>
            <StaticImage src={`../images/Confetti.png`} width={120} />
          </div>
        </div> */}
        <h1 className={`center break-to-words`} id={`page-title`}>Hello, I’m Abhinav</h1>
        <div className={`content--md-center`}>
          <p className={`break-to-words initial-animation primary center lg`} id={`description-1`}>I'm a designer, developer and machine learning enthusiast. I love building innovative engineering solutions with beautiful user experiences.</p>
          <p className={`break-to-words  initial-animation primary center lg`} id={`description-2`}>Currently, MS-HCI student at Georgia Tech, ATL</p>
          <div className={`center-content initial-animation`} id={`about-me-button`}><Link to='/about'><button className={`data-hover`}>More about me</button></Link></div>
        </div>
      </div>
      </div>
    </section>
    <section className={`fix-bottom`}>
      <div className={`content`}>
        <div className={`add-margin-bottom`}>
          <h6>Recents from the workshop</h6>
          <h2>Projects Showcase</h2>
        </div>
        <div className={`projects-wrapper`}>
          {/* <h6>Highlights</h6> */}
          <div className={`cards-wrapper`}>
            {['highMuseum', 'aesop'].map((projectName) => (
              <Card
                imageSelector={projects[projectName].imageSelector}
                title={projects[projectName].title}
                description={projects[projectName].description}
                categories={projects[projectName].categories.join(', ')}
                link={projects[projectName].link}
                date={projects[projectName].date}
                themeColor={projects[projectName].themeColor}
                key={projectName}
              />
            ))}
          </div>
        </div>
        <div className={`projects-wrapper`}>
          <div className={`subtitle`}>
            {/* <h6>In Progress</h6> */}
          </div>
          <div className={`cards-wrapper`}>
            {['botsAtWork', 'workaholic'].map((projectName) => (
              <Card
                imageSelector={projects[projectName].imageSelector}
                title={projects[projectName].title}
                description={projects[projectName].description}
                categories={projects[projectName].categories.join(', ')}
                link={projects[projectName].link}
                date={projects[projectName].date}
                themeColor={projects[projectName].themeColor}
                key={projectName}
              />
            ))}
          </div>
        </div>
        <div className={`center-content`}>
          <Link to='/projects'><button className={`data-hover`}>View all projects</button></Link>
        </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content`}>
        <div className={`add-margin-bottom`}>
          <h6>Stories from the lab</h6>
          <h2>Research</h2>
        </div>
        <div className={`research-wrapper`}>
          {['aesop','postureAnalysis'].map((projectName) => (
            <Research
              imagePath={research[projectName].imagePath}
              title={research[projectName].title}
              description={research[projectName].description}
              tag={research[projectName].tag}
              link={research[projectName].link}
              date={research[projectName].date}
              key={projectName}
            />
          ))}
        </div>
        <div className={`center-content`}>
          <Link to='/research'><button className={`data-hover`}>View research work</button></Link>
        </div>
      </div>
    </section>
  </>
)
}

export default IndexPage
